import { createSlice } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import {
  addConversationMessage,
  setConversationMessages,
} from './conversationMessagesSlice';
import {
  DecoratedConversationMessageFileCollection,
  UndecoratedConversationMessageFileCollection,
} from '@witmetrics/api-client';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type ConversationMessageFileCollectionsState = {
  byID: Record<string, UndecoratedConversationMessageFileCollection>;
  byMessageID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byMessageID: {},
} satisfies ConversationMessageFileCollectionsState as ConversationMessageFileCollectionsState;

export const conversationMessageFileCollectionsSlice = createSlice({
  name: 'conversationMessageFileCollections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byMessageID: {} };
      })
      .addCase(setConversationMessages, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap((m) => m.fileCollections)
        );
      })
      .addCase(addConversationMessage, (state, action) => {
        return getNextState(state, action.payload.fileCollections);
      })
      .addDefaultCase(() => {});
  },
});

function formatConversationMessageFileCollection({
  fileCollection,
  ...conversationMessageFileCollection
}: DecoratedConversationMessageFileCollection) {
  return conversationMessageFileCollection;
}

function getNextState(
  state: ConversationMessageFileCollectionsState,
  conversationMessageFileCollections: DecoratedConversationMessageFileCollection[]
) {
  const byID = { ...state.byID };
  const byMessageID = { ...state.byMessageID };
  conversationMessageFileCollections.forEach((cmfc) => {
    const key = buildConversationMessageFileCollectionKey(
      cmfc.conversationMessageID,
      cmfc.fileCollectionID
    );
    byID[key] = formatConversationMessageFileCollection(cmfc);
    if (!byMessageID[cmfc.conversationMessageID]) {
      byMessageID[cmfc.conversationMessageID] = [];
    }
    byMessageID[cmfc.conversationMessageID] = mergeWithoutDuplicates(
      byMessageID[cmfc.conversationMessageID],
      [key]
    );
  });
  return { byID, byMessageID };
}

export function buildConversationMessageFileCollectionKey(
  messageID: number,
  fileCollectionID: number
) {
  return `${messageID}.${fileCollectionID}`;
}

export const {} = conversationMessageFileCollectionsSlice.actions;

export default conversationMessageFileCollectionsSlice.reducer;
