import { createSlice } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { setConversationMessages, addConversationMessage } from './conversationMessagesSlice';
import {
  DecoratedConversationMessageFile,
  UndecoratedConversationMessageFile,
} from '@witmetrics/api-client';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type ConversationMessageFilesState = {
  byID: Record<string, UndecoratedConversationMessageFile>;
  byMessageID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byMessageID: {},
} satisfies ConversationMessageFilesState as ConversationMessageFilesState;

export const conversationMessageFilesSlice = createSlice({
  name: 'conversationMessageFiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byMessageID: {} };
      })
        .addCase(setConversationMessages, (state, action) => {
          return getNextState(
              state,
              action.payload.flatMap((m) => m.files)
          );
        })
        .addCase(addConversationMessage, (state, action) => {
          return getNextState(
              state,
              action.payload.files
          );
        })
      .addDefaultCase(() => {});
  },
});

function formatConversationMessageFile({
  file,
  ...conversationMessageFile
}: DecoratedConversationMessageFile) {
  return conversationMessageFile;
}

function getNextState(
  state: ConversationMessageFilesState,
  conversationMessageFiles: DecoratedConversationMessageFile[]
) {
  const byID = { ...state.byID };
  const byMessageID = { ...state.byMessageID };
  conversationMessageFiles.forEach((cmf) => {
    const key = buildConversationMessageFileKey(cmf.conversationMessageID, cmf.fileID);
    byID[key] = formatConversationMessageFile(cmf);
    if (!byMessageID[cmf.conversationMessageID]) {
      byMessageID[cmf.conversationMessageID] = [];
    }
    byMessageID[cmf.conversationMessageID] = mergeWithoutDuplicates(
      byMessageID[cmf.conversationMessageID],
      [key]
    );
  });
  return { byID, byMessageID };
}

export function buildConversationMessageFileKey(messageID: number, fileID: number) {
  return `${messageID}.${fileID}`;
}

export const {} = conversationMessageFilesSlice.actions;

export default conversationMessageFilesSlice.reducer;
