import { createSlice } from '@reduxjs/toolkit';
import { setCurrentUser } from './currentUserSlice';
import {
  DecoratedPracticePreference,
  FetchCurrentUserResponse,
  UndecoratedPreference,
} from '@witmetrics/api-client';
import { mapToKey } from '@/utils/arrays';

type PreferencesState = {
  byID: Record<string, UndecoratedPreference>;
};

const initialState = {
  byID: {},
} satisfies PreferencesState as PreferencesState;

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        const preferences = [
          ...flattenUserPreferences(action.payload.userPreferences),
          ...flattenUserPractices(action.payload.userPractices),
        ];
        const byID = { ...state.byID };
        preferences.forEach((preference) => {
          byID[preference.id] = preference;
        });
        state.byID = byID;
      })
      .addDefaultCase(() => {});
  },
});

function flattenUserPractices(
  userPractices: FetchCurrentUserResponse['userPractices']
) {
  return userPractices
    .reduce(
      (a: DecoratedPracticePreference[], up) => [
        ...a,
        ...up.practice.preferences,
      ],
      []
    )
    .map((p) => p.preference);
}

function flattenUserPreferences(
  userPreferences: FetchCurrentUserResponse['userPreferences']
) {
  return mapToKey(userPreferences, 'preference');
}

export default preferencesSlice.reducer;
