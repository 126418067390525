import type { Dictionary } from '@/types';

type ArrayToObjectType<T, F extends string[]> = {
  byID: Dictionary<T>;
} & {
  [K in F[number]]: string[];
};

export function generateEmptyState<T, F extends string[]>(
  additionalFields: F
): ArrayToObjectType<T, F> {
  // const example = generateEmptyState<number, ['byExampleID', 'byUserID']>(['byExampleID', 'byUserID']);
  return {
    byID: {},
    ...additionalFields.reduce(
      (obj, key) => ({ ...obj, [key]: [] }),
      {} as { [K in F[number]]: string[] }
    ),
  };
}

export function generateID(obj: Dictionary<any>, keyStructure: string) {
  const identifier = keyStructure.split('.');
  return identifier.map((id) => obj[id]).join('.');
}
