import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCurrentUser } from './currentUserSlice';
import {
  FetchPracticeUsersResponse,
  UndecoratedPractice,
  UndecoratedUser,
  UndecoratedUserPractice,
} from '@witmetrics/api-client';
import { mergeWithoutDuplicates } from '@/utils/arrays';

const keyStructure = 'userID.practiceID';

type UserPracticesState = {
  byID: Record<string, UndecoratedUserPractice>;
  byUserID: Record<string, string[]>;
  byPracticeID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUserID: {},
  byPracticeID: {},
} satisfies UserPracticesState as UserPracticesState;

export const userPracticesSlice = createSlice({
  name: 'userPractices',
  initialState,
  reducers: {
    setPracticeUsers: (
      state,
      action: PayloadAction<FetchPracticeUsersResponse['results']>
    ) => {
      const userPractices = action.payload.map(formatUserPractice);
      return getNextState(state, userPractices);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        const userPractices =
          action.payload.userPractices.map(formatUserPractice);
        return getNextState(state, userPractices);
      })
      .addDefaultCase(() => {});
  },
});

interface RawUserPractice extends UndecoratedUserPractice {
  user?: UndecoratedUser;
  practice?: UndecoratedPractice;
}

function formatUserPractice({
  user,
  practice,
  ...userPractice
}: RawUserPractice) {
  return userPractice;
}

function getNextState(
  state: UserPracticesState,
  userPractices: UndecoratedUserPractice[]
) {
  const byID = { ...state.byID };
  const byUserID = { ...state.byUserID };
  const byPracticeID = { ...state.byPracticeID };
  userPractices.forEach((up) => {
    const key = buildUserPracticeKey(up.userID, up.practiceID);
    byID[key] = formatUserPractice(up);
    if (!byUserID[up.userID]) byUserID[up.userID] = [];
    if (!byPracticeID[up.practiceID]) byPracticeID[up.practiceID] = [];
    byUserID[up.userID] = mergeWithoutDuplicates(byUserID[up.userID], [key]);
    byPracticeID[up.practiceID] = mergeWithoutDuplicates(
      byPracticeID[up.practiceID],
      [key]
    );
  });
  return { byID, byUserID, byPracticeID };
}

export function buildUserPracticeKey(userID: number, practiceID: number) {
  return `${userID}.${practiceID}`;
}

export const { setPracticeUsers } = userPracticesSlice.actions;

export default userPracticesSlice.reducer;
