import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DecoratedPractice, UndecoratedPractice } from '@witmetrics/api-client';
import { setCurrentUser } from './currentUserSlice';

type PracticeState = {
  byID: Record<string, UndecoratedPractice>;
};

const initialState = { byID: {} } satisfies PracticeState as PracticeState;

export const practicesSlice = createSlice({
  name: 'practices',
  initialState,
  reducers: {
    setPractices: (state, action: PayloadAction<DecoratedPractice[]>) => {
      let byID = { ...state.byID };
      action.payload.forEach((practice) => {
        byID[practice.id] = getFlatPractice(practice);
      });
      state.byID = byID;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        let byID = { ...state.byID };
        action.payload.userPractices.forEach((up) => {
          byID[up.practice.id] = getFlatPractice(up.practice);
        });
        state.byID = byID;
      })
      .addDefaultCase(() => {});
  },
});

function getFlatPractice({
  properties,
  preferences,
  ...flatPractice
}: DecoratedPractice) {
  return flatPractice;
}

export const { setPractices } = practicesSlice.actions;

export default practicesSlice.reducer;
